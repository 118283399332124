<template>
  <Dialog>
    <Form
      id="edit-complaint-contact-data-form"
      submit-label="user.account.profile.editDialog.submit"
      :is-loading="false"
      @submit="(submitData) => onSubmit(submitData)"
      @cancel="dialogStore.closeDialog()"
    >
      <template #default>
        <TextInput
          name="title"
          label="complaintForm.contactDialog.title.label"
          :model-value="data.title"
          required-message="complaintForm.contactDialog.title.requiredMessage"
          :required="false"
          autofocus
        />

        <TextInput
          name="firstname"
          label="complaintForm.contactDialog.firstname.label"
          :model-value="data.firstname"
          required-message="complaintForm.contactDialog.firstname.requiredMessage"
        />

        <TextInput
          name="lastname"
          label="complaintForm.contactDialog.lastname.label"
          :model-value="data.lastname"
          required-message="complaintForm.contactDialog.lastname.requiredMessage"
        />

        <TextInput
          name="email"
          label="complaintForm.contactDialog.email.label"
          :model-value="data.email"
          required-message="complaintForm.contactDialog.email.requiredMessage"
        />

        <PhoneCanonical
          v-model="data.phone"
          :country-code="$languageCode.toUpperCase() as CountryCode"
          :required="true"
        />
      </template>
    </Form>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { useDialogStore } from '@/stores/useDialogStore';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import type { CountryCode } from '@/components/formFields/types';
import { TextInput, PhoneCanonical } from '@/complib';

const dialogStore = useDialogStore();
dialogStore.setHeadline('complaintForm.contactDialog.headline');
const data = dialogStore.getDialogData<{
  title: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}>();

function onSubmit(response: any) {
  const data = {
    title: response?.title ?? '',
    firstname: response?.firstname,
    lastname: response?.lastname,
    email: response?.email,
    phone: response?.phone?.phoneCountry + response?.phone?.phoneNumber,
  };
  dialogStore.closeDialog(data);
}
</script>
